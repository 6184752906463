import { css, Global, useTheme } from '@emotion/react'
import { HeaderQuery } from '@graphql-types'
import { useCountry } from '@pages/{Country.name}/courses/MAP/_locator'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'
import { invert, rgba } from 'polished'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaBars } from 'react-icons/fa'
import { VscGlobe } from 'react-icons/vsc'
import { maxWidth, minWidth } from 'src/themes'
import { globalHistory } from '@reach/router'
import { FormContext } from './Layout'

export default function Header({ formShown }: { formShown: boolean }) {
  const { t, i18n } = useTranslation()
  const { changeLanguage, originalPath, siteUrl = '' } = useI18next()
  const createUrlWithLang = (lng: string) => {
    const url = `${siteUrl.replace(/\/$/, '')}${`/${lng}`}${originalPath}`
    return url.endsWith('/') ? url : `${url}/`
  }
  const theme = useTheme()
  const [navbarOpened, setNavbarOpened] = useState(false)
  const [, , , lastSavedCountry] = useCountry()

  useEffect(() => {
    return globalHistory.listen((props) => {
      if (props.action === 'POP') {
        setNavbarOpened(false)
      }
    })
  }, [setNavbarOpened])

  const data: HeaderQuery = useStaticQuery(graphql`
		query Header {
			en_courses: allCockpitCoursesCollection(
				filter: { _lang: { eq: "en" }, active: { eq: true } }
				sort: { fields: order, order: ASC }
			) {
				nodes {
					name
					code
					mode
				}
			}
			zh_Hant_courses: allCockpitCoursesCollection(
				filter: { _lang: { eq: "zh_Hant" }, active: { eq: true } }
				sort: { fields: order, order: ASC }
			) {
				nodes {
					name
					code
					mode
				}
			}
			en_flexCourses: allCockpitFlexCoursesNewCollection(
				filter: { _lang: { eq: "en" }, active: { eq: true } } # sort: { fields: order, order: ASC }
			) {
				nodes {
					name
					code
				}
			}
			zh_Hant_flexCourses: allCockpitFlexCoursesNewCollection(
				filter: { _lang: { eq: "zh_Hant" }, active: { eq: true } } # sort: { fields: order, order: ASC }
			) {
				nodes {
					name
					code
				}
			}
		}
	`)

  const courses = (
    i18n.language === 'en' ? data.en_courses : data.zh_Hant_courses
  )?.nodes
  const flexCourses = (
    i18n.language === 'en' ? data.en_flexCourses : data.zh_Hant_flexCourses
  )?.nodes

  useEffect(() => {
    const toggleBodyClass = () => {
      if (window.scrollY > 0) {
        document.body.classList.add('scrolled')
      } else {
        document.body.classList.remove('scrolled')
      }
    }
    window.addEventListener('scroll', toggleBodyClass, { passive: true })

    return () => {
      window.removeEventListener('scroll', toggleBodyClass)
    }
  })
  return (
    <nav
      role='navigation'
      aria-label={t('layout:main_menu')}
      onClick={(e) => {
        if ((e.target as HTMLElement).matches('a')) {
          setNavbarOpened(false)
        }
      }}
      css={css`
				display: flex;
				align-items: center;
				height: 110px;
				margin: 0.5rem 2rem;
				box-sizing: border-box;

				a > *,
				button > * {
					pointer-events: none;
				}

				.logo {
					filter: ${theme.imageFilter};
				}

				> button {
					display: none;
				}

				> ul {
					flex: 1;
					font-size: 0.9rem;

					display: flex;
					justify-content: flex-end;
					align-items: center;
					margin: 0;

					> li {
						margin: 0;
						list-style: none;
						position: relative;
						align-items: flex-start;

						> a,
						> span {
							display: flex;
							padding: 0.85rem;
							position: relative;
							justify-content: center;
							align-items: center;
							transition: all 0.3s;
							> .badge {
								position: absolute;
								padding: 0.1rem 0.6rem;
								text-align: center;
								border-radius: 0.4rem;
								font-size: 0.8rem;
								top: -1.2rem;
								left: 0.85rem;
								color: #fff;

								${maxWidth[3]} {
									left: 0.45rem;
									font-size: 0.7rem;
								}
							}

							${maxWidth[3]} {
								padding-right: 0.45rem;
								padding-left: 0.45rem;
							}

              > .badge.second {
								left: 4.85rem;
              }
						}

						> span {
							cursor: default;
						}
						> span::after {
							// dropdown

							content: '';
							position: absolute;
							bottom: 0.5rem;
							width: 0;
							height: 0;
							border-left: 0.3rem solid transparent;
							border-right: 0.3rem solid transparent;
							border-top: 0.3rem solid ${theme.linkColor};
							clear: both;
							transition: all 0.2s;
						}
						&:hover > span::after {
							transform: rotate(180deg);
						}

						> ul {
							display: flex;
							top: 100%;
							left: 0;
							border-top-right-radius: 0.5rem;
							border-bottom-left-radius: 0.5rem;
							border-bottom-right-radius: 0.5rem;
							width: 20rem;
							padding: 0;
							margin: 0;
							list-style-type: none;
							flex-direction: column;
							justify-content: flex-start;
							align-items: stretch;
							position: absolute;
							background: ${rgba(theme.backgroundStatic, 0.8)};
							z-index: 11;
							transition: all 0.3s;
							opacity: 0;
							pointer-events: none;

							a {
								padding: 0.6rem 1rem;
								display: block;
							}
						}
					}

					> li:hover {
						> span {
							border-top-right-radius: 0.5rem;
							border-top-left-radius: 0.5rem;
							background: ${rgba(theme.backgroundStatic, 0.8)};
						}

						> ul {
							display: flex;
							opacity: 1;
							pointer-events: all;
						}
					}
				}

				${maxWidth[2]} {
					transition: 0.25s all;
					position: fixed;
					top: ${formShown ? '-80px' : 0};
					left: 0;
					right: 0;
					height: auto;
					margin: 0;
					z-index: 1000;
					height: calc(48px + 2rem);

					> button {
						margin-left: auto;
						background: transparent;
						border: none;
						padding: 1rem;
						color: ${theme.linkColor};
						font-size: 1.2rem;
						height: 30px;
						box-sizing: content-box;
						display: flex;
						align-items: center;
						transition: 0.25s all;
					}

					.logo {
						margin-left: 1rem;
						width: 125px;
						height: 40px;
						transition: 0.25s all;
					}

					.scrolled & {
						background-color: #fff;
						height: calc(30px + 2rem);

						.logo {
							filter: none;
							width: 93.75px;
							height: 30px;
						}

						> button {
							padding-top: 0.5rem;
							padding-bottom: 0.5rem;
							color: ${theme.buttonColor};
						}
					}

					> ul {
						visibility: hidden;
						pointer-events: none;
						transition: 0.25s all;
						flex-direction: column;
						justify-content: flex-start;
						align-items: flex-start;
						position: fixed;
						top: calc(48px + 2rem);
						left: 0;
						right: 0;
						opacity: 0;
						height: 0;
						padding: 0;
						background: ${theme.backgroundStatic};
						overflow: auto;

						&[data-opened='true'] {
							height: calc(100vh - (48px + 2rem));
							visibility: visible;
							pointer-events: all;
							display: flex;
							opacity: 1;

							.scrolled & {
								top: calc(30px + 2rem);
								height: calc(100vh - (30px + 2rem));
							}
						}

						> li {
							width: 100%;

							> a > .badge,
							> span > .badge {
								top: 0.85rem;
							}

							> ul {
								position: static;
								display: flex;
								height: 0;
								pointer-events: none;
								width: 100%;

								a {
									text-align: center;
								}
							}
						}

						> li:hover {
							> ul {
								display: flex;
								height: 200px;
								pointer-events: all;
							}
							> ul.longer {
								height: 300px;
							}
						}
					}
				}
			`}>
      <Global
        styles={css`
					${maxWidth[2]} {
						body {
							padding-top: calc(48px + 2rem);
						}
					}
				`}
      />
      {navbarOpened && (
        <Global
          styles={css`
						${maxWidth[2]} {
							body {
								overflow: hidden;
							}
						}
					`}
        />
      )}
      <Link to='/'>
        {originalPath === '/' ? (
          <h1
            css={css`
							margin: 0;
							font-size: 1rem;
						`}>
            <StaticImage
              className='logo'
              src='../images/logo_title.png'
              height={48}
              placeholder='blurred'
              alt={t('layout:tecky_academy')}
            />
          </h1>
        ) : (
          <StaticImage
            className='logo'
            src='../images/logo_title.png'
            height={48}
            placeholder='blurred'
            alt={t('layout:tecky_academy')}
          />
        )}
      </Link>
      <button
        title={t('layout:toggle_menu')}
        onClick={() => setNavbarOpened((opened) => !opened)}>
        <FaBars />
      </button>
      <ul data-opened={navbarOpened}>
        <li>
          <span>
            <div
              className='badge'
              css={css`
								background: linear-gradient(115deg, ${theme.fullTimeColor} 35%, ${theme.selfPacedColor} 65%);
							`}>
              {t('layout:full_time_self_paced')}
            </div>
            {t('layout:micromaster')}
          </span>
          <ul className='longer'>
            <li>
              <Link to={(lastSavedCountry === 'hk'
                ? ''
                : '/' + lastSavedCountry) + '/courses/MAP/'
              }>
                {t('layout:micromaster_full_time')}
              </Link>
            </li>
            <li>
              <Link to={'/courses/SMA/'}>
                {t('layout:micromaster_self_paced')}
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <span>
            <div
              className='badge'
              css={css`
								background-color: ${theme.partTimeColor};
							`}>
              {t('layout:part_time')}
            </div>
            {t('layout:pro_courses')}
          </span>
          <ul className='longer'>
            {courses
              ?.filter((node) => node?.mode === 'night-time')
              .map((course, i) => (
                <li key={i}>
                  <Link to={`/courses/${course?.code}/`}>
                    {course?.name}
                  </Link>
                </li>
              ))}
          </ul>
        </li>
        <li>
          <span>
            <div
              className='badge'
              css={css`
								background-color: ${theme.partTimeColor};
							`}>
              {t('layout:part_time')}
            </div>
            {t('layout:flex_courses')}
          </span>
          <ul>
            {flexCourses?.map((course, i) => (
              <li key={i}>
                <Link
                  to={`/courses/FLEX/classes/${course?.code}/`}>
                  {course?.name}
                </Link>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <span>
            <div
              className='badge'
              css={css`
								background-color: ${theme.freeColor};
							`}>
              {t('layout:free')}
            </div>
            {t('layout:resources')}
          </span>
          <ul>
            <li>
              <Link to='/free/tecky-code/'>
                {t('layout:tecky_code')}
              </Link>
            </li>
            <li>
              <Link to='/free/videos/'>{t('layout:videos')}</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to='/events/'>{t('layout:events')}</Link>
        </li>
        <li>
          <Link to='/media/'>{t('layout:media')}</Link>
        </li>
        <li>
          <Link to='/blog/'>{t('layout:blog')}</Link>
        </li>
        <li>
          <Link to='/about-us/'>{t('layout:about_us')}</Link>
        </li>
        <li css={css`
					span {
						display: inline-block;
						padding: 0 0.5rem;
						border-radius: 0.5rem;
						background-color: rgba(255, 255, 255, 0.2);
					}
				`}>
          {i18n.language === 'en' ? <a
            href={createUrlWithLang('zh_Hant')}
            onClick={(e) => {
              e.preventDefault()
              changeLanguage('zh_Hant')
            }}>
            <span>繁體中文</span>
          </a> : <a
            href={createUrlWithLang('en')}
            onClick={(e) => {
              e.preventDefault()
              changeLanguage('en')
            }}>
            <span>English</span>
          </a>}
        </li>
      </ul>
    </nav>
  )
}
